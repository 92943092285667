import React, { useContext, useCallback, useMemo, useRef, type FC } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import {
	FlyoutMenuItem,
	FlyoutMenuItemContent,
	FlyoutMenuItemTrigger,
} from '@atlassian/navigation-system/side-nav/flyout-menu-item';

import { SSRMouseEventWrapper, SSR_NAV_PAGES_BUTTON_METRIC } from '@confluence/browser-metrics';
import { useRouteDataRef } from '@confluence/route-manager';
import { LoadableLazy } from '@confluence/loadable';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { GO_TO_STARRED_SHORTCUT } from '@confluence/shortcuts';
import {
	APP_NAV_PAGES_DROPDOWN_EXPERIENCE,
	ExperienceTrackerContext,
	ExperienceTimeout,
} from '@confluence/experience-tracker';
import { fg } from '@confluence/feature-gating';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';

import { KeyboardShortcutWrapper } from '../KeyboardShortcutWrapper';
import { i18n } from '../globalNavigationTranslations';
import { StarIconComponent } from '../GlobalNavigationIcons';
import { preloadStarredContentMenu } from '../../ContentMenu/preloadStarredContentMenu';
import { ContentMenuLoadingState } from '../../ContentMenu/ContentMenuStates';
import { useFlyoutStore } from '../FlyoutStore';

import { useGlobalItemVisibility } from './useGlobalItemVisibility';
import type { GlobalItemProps } from './globalItemProps';

export const StarredContentMenuLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-StarredContentMenu" */
				'../../ContentMenu/StarredContentMenu'
			)
		).StarredContentMenu,
	loading: () => <ContentMenuLoadingState />,
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ '../analyticsCallbacks'))
			.fireStarredClickedAnalytics,
);

const FLYOUT_LABEL = 'STARRED';

export const StarredItem: FC<GlobalItemProps> = ({ isHidden, peekingId, setPeekingId }) => {
	const [flyoutState, flyoutActions] = useFlyoutStore();
	const isFlyoutOpen = useMemo(() => flyoutState.openFlyout === FLYOUT_LABEL, [flyoutState]);
	const openFlyout = useCallback(() => flyoutActions.openFlyout(FLYOUT_LABEL), [flyoutActions]);
	const closeFlyout = useCallback(() => flyoutActions.closeFlyout(FLYOUT_LABEL), [flyoutActions]);

	const routeDataRef = useRouteDataRef();
	const isMounted = useRef(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();

	const abortExperienceTracker = useCallback(() => {
		experienceTracker.abort({
			name: APP_NAV_PAGES_DROPDOWN_EXPERIENCE,
			reason: 'Primary dropdown menu: closed by user',
			attributes: {
				navVersion: '4',
				dropdownType: 'starred',
			},
		});
	}, [experienceTracker]);

	const startExperienceTracker = useCallback(() => {
		experienceTracker.start({
			name: APP_NAV_PAGES_DROPDOWN_EXPERIENCE,
			timeout: ExperienceTimeout.NAVIGATION_LOAD,
			attributes: {
				navVersion: '4',
				dropdownType: 'starred',
			},
		});
	}, [experienceTracker]);

	const onOpenChange = useCallback(
		(newIsOpen: boolean) => {
			if (!isMounted.current) {
				isMounted.current = true;
				return;
			}
			if (!newIsOpen) {
				abortExperienceTracker();
				void fireClickAnalytics(false);
				isFlyoutOpen && closeFlyout();
			} else {
				openFlyout();
			}
		},
		[abortExperienceTracker, fireClickAnalytics, isFlyoutOpen, closeFlyout, openFlyout],
	);

	const onShortcutTriggered = useCallback(() => {
		if (!isFlyoutOpen) {
			void fireClickAnalytics(true, true, isHidden);
			startExperienceTracker();
			openFlyout();
		}
	}, [fireClickAnalytics, isFlyoutOpen, startExperienceTracker, isHidden, openFlyout]);

	const onClick = useCallback(() => {
		if (!isFlyoutOpen) {
			openFlyout();
			startExperienceTracker();
			void fireClickAnalytics(true); //if we clicked on it we know it's not hidden or fired via keyboard shortcut
		} else {
			closeFlyout();
		}
	}, [fireClickAnalytics, isFlyoutOpen, startExperienceTracker, openFlyout, closeFlyout]);

	const onHover = useCallback(() => {
		if (!isFlyoutOpen) {
			void StarredContentMenuLoader.preload();
			void preloadStarredContentMenu();
		}
	}, [isFlyoutOpen]);

	const isSelected = false; //toDo: this will be the all Starred URL once flyouts support selected state
	const shouldHide = useGlobalItemVisibility(
		'starred',
		isSelected || isFlyoutOpen,
		isHidden,
		peekingId,
		setPeekingId,
	);

	if (shouldHide) {
		return null;
	}

	return (
		<SSRMouseEventWrapper metricName={SSR_NAV_PAGES_BUTTON_METRIC}>
			<KeyboardShortcutWrapper
				keyboardShortcut={
					isLiveEditMode && fg('confluence_frontend_live_edit_keyboard_shortcut')
						? undefined
						: GO_TO_STARRED_SHORTCUT
				}
				isAppNavigationShortcut
				onShortcutTriggered={onShortcutTriggered}
			>
				{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
				<div onMouseEnter={onHover}>
					<FlyoutMenuItem onOpenChange={onOpenChange} isOpen={isFlyoutOpen}>
						<FlyoutMenuItemTrigger iconBefore={StarIconComponent} onClick={onClick}>
							<FormattedMessage {...i18n.starred} />
						</FlyoutMenuItemTrigger>
						<FlyoutMenuItemContent onClose={() => isFlyoutOpen && closeFlyout()}>
							<StarredContentMenuLoader />
						</FlyoutMenuItemContent>
					</FlyoutMenuItem>
				</div>
			</KeyboardShortcutWrapper>
		</SSRMouseEventWrapper>
	);
};
